import Button from 'components/dist/atoms/Button';
import Checkbox from 'components/dist/atoms/Checkbox';
import Editor from 'components/dist/atoms/Editor';
import Icon from 'components/dist/atoms/Icon';
import Label from 'components/dist/atoms/Label';
import Stack from 'components/dist/atoms/Stack';
import Switch from 'components/dist/atoms/Switch';
import Text from 'components/dist/atoms/Text';
import ActionAlertDialog from 'components/dist/molecules/ActionAlertDialog';
import FilesDropContainer from 'components/dist/molecules/FilesDropContainer';
import RecipientSelect from 'components/dist/organisms/RecipientSelect';
import { forwardRef } from 'react';
import { MysherpasTooltip } from 'src/components/common/mysherps-tooltip';
import { RenderIf } from 'src/components/common/render-if';
import { SecureUploadFormFilesListItem } from 'src/components/secure-upload-form/secure-upload-form-files-list-item.component';
import { classNames } from 'src/utils/tailwind-class-names';

import { MessageReplyToListItem } from '../message-reply-to-list-item';
import { useInlineComposeMessageForm } from './inline-compose-message-form.state';
import { InlineComposeMessageFormProps, InlineComposeMessageFormRefProps } from './inline-compose-message-form.types';
import { InlineComposeMessageFormSubject } from './inline-compose-message-form-subject.component';

export const InlineComposeMessageForm = forwardRef<InlineComposeMessageFormRefProps, InlineComposeMessageFormProps>((props, ref) => {
    const state = useInlineComposeMessageForm(props, ref);
    return <>
        <FilesDropContainer
            activeClassName='outline-blue-60 outline-2 outline'
            className='flex-1 flex flex-col overflow-hidden rounded-md'
            onFilesDrop={state.onFilesDrop} multiple>
            <Stack
                className={classNames('flex-1 overflow-hidden', {
                    'fixed w-full h-full bg-white md:bg-transparent md:static z-dialog top-0 left-0 overflow-hidden': state.isMessageDialogOpen,
                })}
            >
                <Stack
                    space="sm"
                    className={classNames('py-2 pl-2 pr-4 md:hidden ', {
                        hidden: !state.isMessageDialogOpen,
                    })} items="center" row>
                    <Button
                        circle
                        size="xs"
                        className='p-0'
                        variant="secondary"
                        onClick={state.onCancel}>
                        <Icon name="Cancel" width={16} height={16} strokeWidth={1.5} />
                    </Button>
                    <Text size="sm">New Message</Text>
                </Stack>
                <Stack
                    className={classNames(' sm:border-0 flex-1 flex-row overflow-hidden items-stretch', {
                        'p-2 md:p-0 border': state.isMessageDialogOpen,
                    })}
                    space="sm"
                    asChild>
                    <form onSubmit={state.handleSubmit(state.onSubmit)}>
                        <Stack
                            className={classNames('md:h-[50px] lg:hidden pb-1 sm:pb-2', {
                                'hidden': state.isMessageDialogOpen
                            })}
                            items="end"
                            row>
                            <Button
                                circle
                                size="sm"
                                className={'px-2 w-6 h-6 mb-1 rounded-full text-blue-100 border-blue-100 '}
                                data-upload-trigger='true'
                                variant="ghost">
                                <Icon name="Plus" width={20} height={20} strokeWidth={2} />
                            </Button>
                        </Stack>
                        <Stack
                            className={classNames('border sm:border-0 sm:border-0 sm:group-[.with-border]:border sm:group-[.with-border]:border-gray-neutral-80 h-full md:h-auto gap-2  md:gap-0 p-2 md:px-4 py-0 md:py-px rounded-xl flex-1 bg-white overflow-hidden', {
                                '': !state.isMessageDialogOpen,
                            })}>
                            <Stack
                                space="sm"
                                row
                                justify="end"
                                className={classNames('py-2 px-3 -mx-2 border-b md:border-0 border-gray-neutral-80', {
                                    'md:hidden': state.isMessageDialogOpen,
                                    'hidden': !state.isMessageDialogOpen,
                                })}>
                                <Button
                                    size="sm"
                                    className='px-2'
                                    data-upload-trigger='true'
                                    variant="ghost">
                                    <Icon name="Upload" width={16} height={16} />
                                </Button>
                                <Button
                                    className={classNames('p-2', {
                                        'text-blue-100 ': state.isRecipientOpen,
                                    })}
                                    size="sm"
                                    onClick={state.onToggleRecipient}
                                    variant="ghost">
                                    <Icon name="AddUser" width={18} height={18} strokeWidth={1.5} />
                                </Button>
                                {props.subjectVisible && <Button
                                    className={classNames('p-2', {
                                        'text-blue-100 ': state.isSubjectOpen,
                                    })}
                                    size="sm"
                                    onClick={state.onSubjectToggle}
                                    variant="ghost">
                                    <Icon name="Thread" width={18} height={18} strokeWidth={1.5} />
                                </Button>}
                                <Button
                                    disabled={!state.isDigestEnabled}
                                    className={classNames('px-2')}
                                    size="sm"
                                    onClick={state.onAddDigestClick}
                                    variant="ghost">
                                    <Icon name="TimeSchedule" width={18} height={18} strokeWidth={1.5} />
                                </Button>
                                <div className='items-center flex'>
                                    <Switch
                                        onCheckedChange={state.onPrivateCheckedChange}
                                        thumbIcon="Lock"
                                        className='data-[state=unchecked]:bg-white' />
                                </div>
                                {'deleteVisible' in props && props.deleteVisible && <Button
                                    size="sm"
                                    className='px-2'
                                    onClick={state.onDeleteClick}
                                    variant="ghost">
                                    <Icon name="Trash" width={18} height={18} strokeWidth={1.5} />
                                </Button>}
                                <Button
                                    loading={state.isSubmitting}
                                    className='px-2'
                                    size="sm"
                                    onClick={state.handleSubmit(state.onSubmit)}
                                    variant={state.isValid ? "default" : "secondary"}>
                                    <Icon name="SendDiagonal" width={20} height={20} />
                                </Button>
                            </Stack>
                            {props.subjectVisible && state.isSubjectOpen && <div className='border rounded-md md:rounded-none overflow-hidden md:border-t-0 md:border-l-0 md:border-r-0 border-gray-neutral-80'>
                                <InlineComposeMessageFormSubject
                                    threads={state.threads}
                                    recipients={state.recipients}
                                    loanId={state.loanId}
                                    watch={state.watch}
                                    setValue={state.setValue} />
                            </div>}
                            {(props.recipientVisible && (state.isRecipientOpen || (state.isNewThread && !!state.subject))) && <div className='border rounded-md md:rounded-none overflow-hidden md:border-t-0 md:border-l-0 md:border-r-0 border-gray-neutral-80'>
                                <RecipientSelect
                                    options={state.recipientsList}
                                    placeholder="Recipients"
                                    value={state.defaultRecipients}
                                    onChange={(ids: any) => {
                                        state.onRecipientSelected(ids.map(({ value }) => value))
                                    }}
                                />
                            </div>}
                            <Stack
                                className={classNames('flex-1 flex flex-row group-[.is-message-window]:flex-col overflow-hidden', {
                                    'border-destructive': state.errors.body,
                                })}>

                                <div className={classNames('w-full flex group-[.is-message-window]:flex-1  [&>div:last-of-type]:sticky [&>div:last-of-type]:bottom-0 overflow-hidden', {
                                    'pt-2': state.replyToMessage,
                                    'flex-col': state.isEditorOpen || state.isMessageDialogOpen,
                                    'flex-row': !state.isEditorOpen && !state.isMessageDialogOpen,
                                })}>

                                    <Editor.Input
                                        {...state.bodyProps}
                                        enterSubmit={props.enterSubmit}
                                        onChange={(message) => {
                                            state.onBodyChange(message)
                                        }}
                                        // key={state.placeholder}
                                        onReady={state.onEditorReady}
                                        onEnter={() => {
                                            if (!state.isSubmitting) {
                                                state.handleSubmit(state.onSubmit)()
                                            }
                                        }}
                                        className={classNames('px-2 py-2 md:py-3 text-sm flex-1 flex flex-col', {
                                            '': !state.isMessageDialogOpen,
                                        })}

                                        enabledTools={[
                                            "toggleBold", "toggleItalic", "toggleBulletList", "toggleOrderedList"
                                        ]}
                                        placeholder={state.placeholder}
                                        toolbarClassName={classNames('bg-white', {
                                            'md:hidden': !state.isEditorOpen,
                                            'hidden md:flex': state.isEditorOpen,
                                            'hidden': !state.isMessageDialogOpen,
                                        })}
                                        value={state.body}
                                        toolbar={state.isEditorOpen ? "AFTER" : "BEFORE"} >
                                        <Editor.Prefix>
                                            {state.replyToMessage && <MessageReplyToListItem
                                                withAvatar
                                                variant='BLACK'
                                                onCancel={state.onCancelReplyToMessage}
                                                message={state.replyToMessage} />}
                                        </Editor.Prefix>
                                        <Editor.Suffix>
                                            {state.uploadedFiles.length > 0 && <div className='max-w-lg [&>div>div]:bg-black-10 [&>div>div]:rounded'>
                                                <Stack space="sm" className='py-4'>
                                                    {state.uploadedFiles.map((listFile) => (<SecureUploadFormFilesListItem
                                                        key={listFile.id}
                                                        item={listFile}
                                                        documentName={listFile.documentName}
                                                        progress={listFile.progress}
                                                        status={listFile.status}
                                                        title={listFile.documentName}
                                                        onCancelSingleFile={state.onCancelSingleFile}
                                                        onStageSingleFileForDelete={state.onStageSingleFileForDelete}
                                                        onStageSingleFileForRename={state.onStageSingleFileForRename}
                                                        onFileClick={state.onFileClick}
                                                    />))}
                                                </Stack></div>}
                                        </Editor.Suffix>
                                        <Editor.Footer>
                                            <Stack row items="center" className='self-end md:h-[42px] lg:h-[50px] items-center justify-end flex-1'>
                                                <MysherpasTooltip title="Formatting Options">
                                                    <Button
                                                        variant="ghost"
                                                        onClick={state.onEditorToggle}
                                                        className={classNames(' w-10 underline underline-offset-2 hidden md:inline-flex', {
                                                            'text-blue-100': state.isEditorOpen,
                                                        })}
                                                    >A</Button>
                                                </MysherpasTooltip>
                                                <MysherpasTooltip title="Upload Files">
                                                    <Button
                                                        className={classNames(' w-10 hidden lg:inline-flex')}
                                                        data-upload-trigger='true'
                                                        variant="ghost">
                                                        <Icon name="Upload" width={16} height={16} />
                                                    </Button>
                                                </MysherpasTooltip>
                                                {(props.recipientVisible && !(state.isNewThread && !!state.subject)) &&
                                                    <MysherpasTooltip title="Add Recipient">
                                                        <Button
                                                            className={classNames('w-10', {
                                                                'hidden sm:inline-flex': state.isMessageDialogOpen,
                                                                'text-blue-100': state.isRecipientOpen,
                                                            })}
                                                            onClick={state.onToggleRecipient}
                                                            variant="ghost">
                                                            <Icon name="AddUser" width={18} height={18} strokeWidth={1.5} />
                                                        </Button>
                                                    </MysherpasTooltip>}
                                                {props.subjectVisible && <MysherpasTooltip title="New Subject Thread">
                                                    <Button
                                                        className={classNames('w-10 hidden md:inline-flex', {
                                                            'text-blue-100 ': state.isSubjectOpen,
                                                        })}
                                                        onClick={state.onSubjectToggle}
                                                        variant="ghost">
                                                        <Icon name="Thread" width={18} height={18} strokeWidth={1.5} />
                                                    </Button>
                                                </MysherpasTooltip>}
                                                {props.recipientVisible && <RenderIf condition={state.isLoggedInUserALender}>
                                                    <MysherpasTooltip title="Add Digest">
                                                        <Button
                                                            disabled={!state.isDigestEnabled}
                                                            className={classNames('w-10 hidden md:inline-flex', {
                                                                '': state.isDigestEnabled,
                                                            })}
                                                            onClick={state.onAddDigestClick}
                                                            variant="ghost">
                                                            <Icon name="TimeSchedule" width={18} height={18} strokeWidth={1.5} />
                                                        </Button>
                                                    </MysherpasTooltip>
                                                </RenderIf>}
                                                {state.isNewThread && <RenderIf condition={state.isLoggedInUserALender}>
                                                    <MysherpasTooltip title="Private Message">
                                                        <Switch
                                                            onCheckedChange={state.onPrivateCheckedChange}
                                                            thumbIcon="Lock"
                                                            className='data-[state=unchecked]:bg-white hidden md:inline-flex' />
                                                    </MysherpasTooltip>
                                                </RenderIf>}
                                                {'deleteVisible' in props && props.deleteVisible &&
                                                    <MysherpasTooltip title="Delete Message">
                                                        <Button
                                                            className={classNames('w-10 hidden md:inline-flex', {
                                                                'hidden md:inline-flex': state.isMessageDialogOpen,
                                                            })}
                                                            onClick={state.onDeleteClick}
                                                            variant="ghost">
                                                            <Icon name="Trash" width={18} height={18} strokeWidth={1.5} />
                                                        </Button>
                                                    </MysherpasTooltip>}
                                                <MysherpasTooltip title="Send message">
                                                    <Button
                                                        loading={state.isSubmitting}
                                                        onClick={state.handleSubmit(state.onSubmit)}
                                                        variant={state.isValid ? "default" : "secondary"}
                                                        className={classNames('px-3 w-9 h-9 rounded-lg hidden sm:group-[.is-message-window]:inline-flex', {
                                                            'bg-gray-neutral-30': !state.isValid,
                                                            'bg-blue-100': state.isValid,
                                                        })}>
                                                        <Icon name="SendDiagonal" width={20} height={20} />
                                                    </Button>
                                                </MysherpasTooltip>
                                            </Stack>
                                        </Editor.Footer>
                                    </Editor.Input>

                                </div>

                            </Stack>
                        </Stack>
                        <Stack
                            className={classNames('md:h-[42px] lg:h-[50px] group-[.is-message-window]:hidden self-end',
                                {
                                    'hidden md:inline-flex': state.isMessageDialogOpen,
                                }
                            )}
                            row
                            items='center'
                            space="xs" >
                            <MysherpasTooltip title="Send message">
                                <Button
                                    loading={state.isSubmitting}
                                    disabled={state.isSubmitting}
                                    onClick={state.handleSubmit(state.onPreSubmit)}
                                    variant={state.isValid ? "default" : "secondary"}
                                    className={classNames('px-3 w-9 h-9 rounded-lg', {
                                        'bg-gray-neutral-30': !state.isValid,
                                        'bg-blue-100': state.isValid,
                                    })}>
                                    <Icon name="SendDiagonal" width={20} height={20} />
                                </Button>
                            </MysherpasTooltip>
                        </Stack>
                    </form>
                </Stack>
            </Stack>
        </FilesDropContainer>

        <ActionAlertDialog
            variant="danger"
            confirmButtonText="Yes, Continue"
            onOpenChange={(isOpen) => state.setIsDialogOpen(isOpen)}
            onConfirm={state.onDialogConfirm}
            open={state.isDialogOpen}
            message={<>You’re sending this message in an external thread. Are you sure you want to continue?
                <Stack className='flex items-center mt-4'>
                    <Label htmlFor="sendPublicMessageReminder" className='flex gap-2 items-center' >
                        <Checkbox
                            id='sendPublicMessageReminder'
                            name='sendPublicMessageReminder'
                            onCheckedChange={(checked: boolean) => {
                                state.setValue('sendPublicMessageReminder', checked)
                            }}
                        /> Don&apos;t ask me again
                    </Label>
                </Stack>

            </>}
        /></>
})

InlineComposeMessageForm.displayName = 'InlineComposeMessageForm';