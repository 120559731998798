import Tooltip from 'components/dist/atoms/Tooltip';
import { forwardRef } from 'react';

interface MysherpasTooltipProps {
    title: React.ReactNode;
    hidden?: boolean;
    delayDuration?: number;
    align?: "center" | "start" | "end";
    asChild?: boolean;
}

export const MysherpasTooltip = forwardRef<HTMLButtonElement, React.PropsWithChildren<MysherpasTooltipProps>>((props, ref) => {
    const { title, align = 'start', delayDuration = 700, children, asChild = true } = props;

    if (props.hidden) return <>{children}</>

    return <Tooltip.Provider>
        <Tooltip delayDuration={delayDuration}>
            <Tooltip.Trigger
                aria-label={typeof title === 'string' ? title : undefined}
                asChild={asChild}
                ref={ref}>
                {props.children}
            </Tooltip.Trigger>
            <Tooltip.Portal>
                <Tooltip.Content
                    align={align}
                    side="bottom"
                    alignOffset={0}
                    className="max-w-md text-xs">
                    {title}
                </Tooltip.Content>
            </Tooltip.Portal>
        </Tooltip>
    </Tooltip.Provider>
});

MysherpasTooltip.displayName = 'MysherpasTooltip';