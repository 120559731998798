import Badge from "components/dist/atoms/Badge";
import Button from "components/dist/atoms/Button";
import Checkbox from "components/dist/atoms/Checkbox";
import Dialog from "components/dist/atoms/Dialog";
import Icon from "components/dist/atoms/Icon";
import Label from "components/dist/atoms/Label";
import Separator from "components/dist/atoms/Separator";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";
import UserNameText from "components/dist/molecules/UserNameText";
import { UserAvatar } from "src/components/user/user-avatar";
import { getInitials } from "src/utils/get-initials";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";

import { useElementsSharingDialogState } from "./elements-sharing-dialog.state";
import { ElementsSharingDialogProps } from "./elements-sharing-dialog.types";

export const ElementsSharingDialog = (props: ElementsSharingDialogProps['props']) => {
    const state = useElementsSharingDialogState(props);

    return <Dialog
        onOpenChange={props.onDialogOpenChange}
        open={props.open}>
        <Dialog.Content className="h-full sm:h-auto sm:max-w-3xl gap-0">
            <Dialog.Title className="flex items-center gap-2 font-normal px-5 py-2 leading-6">
                <Icon name="BigExclamation" className="text-black-10" width={32} height={32} /> {state.dialogTitle}
            </Dialog.Title>
            <Separator />
            <Stack className="p-4 h-full sm:h-96 scrollbar-stable overflow-auto max-h-full">
                {state.groups.map(group => (<Stack key={group.key} space="md">
                    <Stack row space="sm" className="bg-black-10 p-1 border-b border-neutral-gray-80">
                        <Badge variant="secondary" circle>{group.users.length}</Badge>
                        <Text size="sm" weight="medium" className="flex-1">{group.title}</Text>
                    </Stack>
                    {state.totalUsers > 1 && <Label className="gap-2 flex flex-row items-center">
                        <Checkbox
                            checked={group.users.every(user => state.userElementsSharing[user.id] === "ASSIGN")}
                            onCheckedChange={checked => {
                                state.onGroupCheckedChange(checked, group.key);
                            }}
                            size='sm' />
                        Select all
                    </Label>}
                    <Stack space="md" className="pl-4 pb-4">
                        {group.users.map(user => (<Stack
                            row
                            space="xs"
                            key={user.id}>
                            <Stack row space="sm" className="flex-1" items="center">
                                {state.totalUsers > 1 && <Checkbox
                                    checked={state.userElementsSharing[user.id] === "ASSIGN"}
                                    onCheckedChange={checked => state.onSelectValueChange(checked ? "ASSIGN" : "", user.id)}
                                    size='sm' />}
                                <UserAvatar
                                    userId={user.id}
                                    avatarDocId={user.avatarDocId}
                                    role={user.loggedCompanyRole}
                                    size='xs'
                                >
                                    {getInitials(getUserDisplayName(user))}
                                </UserAvatar>
                                <UserNameText
                                    size="sm"
                                    user={user}
                                    isMe={state.loggedInUserId === user.id}
                                />
                                {!user.isVisibleToBorrower && <Text size="xs" variant='secondary'>(Not visible)</Text>}
                            </Stack>
                        </Stack>))}
                    </Stack>
                </Stack>))}
            </Stack>
            <Dialog.Footer className="flex flex-row justify-end gap-2">
                <Dialog.Close asChild>
                    <Button
                        disabled={state.isLoading}
                        variant="outline">Cancel</Button>
                </Dialog.Close>
                <Button
                    loading={state.isLoading}
                    onClick={state.onContinueClick}>
                    {state.sendActionText}
                </Button>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog>
};