// a function that takes a url and return the final url after redirecting
export const getActionLinkRedirectUrl = async (url: string): Promise<string> => {
    try {
        const response = await fetch(getAbsoluteUrl(url), { method: 'HEAD', redirect: 'follow' });
        return getAbsoluteUrl(response.url || url);
    } catch (error) {
        console.error('Error fetching url', error)
        return url;
    }
};

//  a function that takes a url and takes all the parts after domain
// and return a new url with current domain
// appended with the parts
// eg http://localhost:3000/api/v1/action?id=5ecee3b3-a846-4181-bd7c-c18e446279c5
// and current domain is https://example.com
// then the function will return https://example.com/api/v1/action?id=5ecee3b3-a846-4181-bd7c-c18e446279c5
// it should handle different domains
// eh http://localhost:3000, http://somethgin.localhost:3000/, http://or.localhost:3000/api/v1/action
export const getAbsoluteUrl = (url: string): string => {
    const urlObj = new URL(url);
    return `${window.location.origin}${urlObj.pathname}${urlObj.search}`;
};