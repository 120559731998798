import { AppUserDTO2, LoanRoleDto, MessageDto, MessageLabel, MessageSendDto } from "src/backend";
import { ElementsSharingDialogProps } from "src/components/form-elements/elements-sharing-dialog/elements-sharing-dialog.types";
import { MessageFormInputs } from "src/components/messages/message-compose-form/message-compose-form.types";
import { MessageDtoExtended } from "src/services/messageApi";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";


export type MessageWindowDialogState = 'OPEN' | 'CLOSED' | 'MINIMIZED' | 'MAXIMIZED';

interface MessageWindowState {
    dialog: MessageWindowDialogState;
    loanId: string | null;
    isRecipientOpen: boolean;
}
interface MessagesState {
    filterLabel: MessageLabel;
    messageComposerOpen: boolean;
    filterQuery: string;
    filterOrder: 'ASC' | 'DESC';
    filterUnread: boolean;
    filterDraft: boolean;
    replyToMessage: MessageDtoExtended | null;
    isConfirmSendPrivateThreadDialogOpen: MessageSendDto | null;
    draftMessage: MessageDto | null;
    isConfirmSendingAttachmentsToPublicThreadDialogOpen: MessageSendDto | null;
    editorFormValues: Partial<MessageFormInputs> | null
    originalMessageToViewAsHtml: MessageDtoExtended | null;
    messageWindowState: MessageWindowState;
    needsListStagedElements: FormElementV2ResponseDtoExtended[];
    addDigestWarningDialogUsers: string[];
    isSubmitting: boolean;
    assignDialogElements: {
        elements: FormElementV2ResponseDtoExtended[],
        users: ElementsSharingDialogProps['props']['shareWithUsers'],
        sharedWithUsers: AppUserDTO2[],
        message: MessageSendDto,
        isOpen: boolean
    },
    invisibleLendersDialog: {
        message: MessageSendDto,
        dialogMessage: string,
        invisibleLenderRoles: LoanRoleDto[],
        isLoading: boolean
    }
}

const messagesContextInitialState: MessagesState = {
    filterLabel: null,
    messageComposerOpen: false,
    filterQuery: '',
    filterOrder: 'DESC',
    replyToMessage: null,
    filterUnread: false,
    filterDraft: false,
    draftMessage: null,
    isConfirmSendPrivateThreadDialogOpen: null,
    isConfirmSendingAttachmentsToPublicThreadDialogOpen: null,
    originalMessageToViewAsHtml: null,
    isSubmitting: false,
    messageWindowState: {
        dialog: 'CLOSED',
        loanId: null,
        isRecipientOpen: true
    },
    needsListStagedElements: [],
    addDigestWarningDialogUsers: [],
    editorFormValues: null,
    assignDialogElements: {
        elements: [],
        users: [],
        sharedWithUsers: [],
        message: null,
        isOpen: false
    },
    invisibleLendersDialog: {
        message: null,
        dialogMessage: '',
        invisibleLenderRoles: [],
        isLoading: false
    }
}

enum Action {
    setFilterLabel = 'setFilterLabel',
    setIsSubmitting = 'setIsSubmitting',
    setMessageComposerOpen = 'setMessageComposerOpen',
    setReplyToMessage = 'setReplyToMessage',
    setConfirmSendPrivateThreadDialogOpen = 'setConfirmSendPrivateThreadDialogOpen',
    setConfirmSendingAttachmentsToPublicThreadDialogOpen = 'setConfirmSendingAttachmentsToPublicThreadDialogOpen',
    setFilterQuery = 'setFilterQuery',
    setFilterOrder = 'setFilterOrder',
    setEditorFormValues = 'setEditorFormValues',
    setOriginalMessageToViewAsHtml = 'setOriginalMessageToViewAsHtml',
    setFilterUnread = 'setFilterUnread',
    setFilterDraft = 'setFilterDraft',
    setDraftMessage = 'setDraftMessage',
    setMessageWindowState = 'setMessageWindowState',
    setNeedsListStagedElements = 'setNeedsListStagedElements',
    setAddDigestWarningDialogUsers = 'setAddDigestWarningDialogUsers',
    setAssignDialogElements = 'setAssignDialogElements',
    setInvisibleLendersDialog = 'setInvisibleLendersDialog',
}

type MessagesAction =
    | { type: Action.setFilterLabel, payload: MessageLabel }
    | { type: Action.setMessageComposerOpen, payload: boolean }
    | { type: Action.setReplyToMessage, payload: MessageDtoExtended }
    | { type: Action.setConfirmSendPrivateThreadDialogOpen, payload: MessageSendDto }
    | { type: Action.setDraftMessage, payload: MessageDto }
    | { type: Action.setFilterQuery, payload: string }
    | { type: Action.setFilterOrder, payload: 'ASC' | 'DESC' }
    | { type: Action.setEditorFormValues, payload: MessagesState['editorFormValues'] }
    | { type: Action.setOriginalMessageToViewAsHtml, payload: MessageDtoExtended }
    | { type: Action.setFilterUnread, payload: boolean }
    | { type: Action.setFilterDraft, payload: boolean }
    | { type: Action.setConfirmSendingAttachmentsToPublicThreadDialogOpen, payload: MessageSendDto }
    | { type: Action.setMessageWindowState, payload: MessageWindowState }
    | { type: Action.setNeedsListStagedElements, payload: FormElementV2ResponseDtoExtended[] }
    | { type: Action.setAddDigestWarningDialogUsers, payload: string[] }
    | {
        type: Action.setAssignDialogElements, payload: MessagesState['assignDialogElements']
    }
    | {
        type: Action.setInvisibleLendersDialog, payload: MessagesState['invisibleLendersDialog']
    } |
    { type: Action.setIsSubmitting, payload: boolean }

const messagesContextReducer = (state: MessagesState, action: MessagesAction): MessagesState => {

    switch (action.type) {
        case Action.setFilterLabel:
            return {
                ...state,
                filterLabel: action.payload
            }
        case Action.setMessageComposerOpen:
            return {
                ...state,
                messageComposerOpen: action.payload
            }
        case Action.setReplyToMessage:
            return {
                ...state,
                replyToMessage: action.payload
            }
        case Action.setConfirmSendPrivateThreadDialogOpen:
            return {
                ...state,
                isConfirmSendPrivateThreadDialogOpen: action.payload
            }
        case Action.setFilterQuery:
            return {
                ...state,
                filterQuery: action.payload
            }
        case Action.setFilterOrder:
            return {
                ...state,
                filterOrder: action.payload
            }
        case Action.setEditorFormValues:
            return {
                ...state,
                editorFormValues: action.payload
            }
        case Action.setOriginalMessageToViewAsHtml:
            return {
                ...state,
                originalMessageToViewAsHtml: action.payload
            }
        case Action.setFilterUnread:
            return {
                ...state,
                filterUnread: action.payload
            }
        case Action.setFilterDraft:
            return {
                ...state,
                filterDraft: action.payload
            }

        case Action.setDraftMessage:
            return {
                ...state,
                draftMessage: action.payload
            }

        case Action.setConfirmSendingAttachmentsToPublicThreadDialogOpen:
            return {
                ...state,
                isConfirmSendingAttachmentsToPublicThreadDialogOpen: action.payload
            }
        case Action.setMessageWindowState:
            return {
                ...state,
                messageWindowState: action.payload
            }
        case Action.setNeedsListStagedElements:
            return {
                ...state,
                needsListStagedElements: action.payload
            }
        case Action.setAddDigestWarningDialogUsers:
            return {
                ...state,
                addDigestWarningDialogUsers: action.payload
            }
        case Action.setAssignDialogElements:
            return {
                ...state,
                assignDialogElements: action.payload
            }
        case Action.setInvisibleLendersDialog:
            return {
                ...state,
                invisibleLendersDialog: action.payload
            }
        case Action.setIsSubmitting:
            return {
                ...state,
                isSubmitting: action.payload
            }
        default:
            return state;
    }
}

export const MessagesContextReducer = {
    initialState: messagesContextInitialState,
    reducer: messagesContextReducer,
    action: Action
}