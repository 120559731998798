
import { RecipientSelectProps } from "components/dist/organisms/RecipientSelect";
import { trigger } from "polyrhythm";
import { useListener } from "polyrhythm-react";
import { ForwardedRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { AppUserDTO2, MessageThreadDto } from "src/backend";
import { UserAvatar } from "src/components/user/user-avatar";
import { DESKTOP_MEDIA_QUERY } from "src/constants/ui";
import { useMessagesContext } from "src/contexts/messages-context";
import { useGetRecipientsUsers } from "src/hooks/messages/use-get-recipients-list";
import { useUser } from "src/hooks/use-user";
import { taskApi } from "src/services/taskApi";
import { useDispatch } from "src/store";
import { getInitials } from "src/utils/get-initials";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";
import { isValidEmail } from "src/utils/validation";
import { useInterval, useMediaQuery } from "usehooks-ts";

import { useMessagesComposeForm } from "../message-compose-form/message-compose-form.state";
import { MessageFormInputs } from "../message-compose-form/message-compose-form.types";
import { InlineComposeMessageFormProps, InlineComposeMessageFormRefProps } from "./inline-compose-message-form.types";

interface InputState {
    isEditorOpen: boolean
    isRecipientOpen: boolean
    isSubjectOpen: boolean
}

const hasDraftValues = (values: MessageFormInputs) => {
    return !!values.body || !!values.recipients.length || !!values.subject || values.attachments.length > 0
}

export const useInlineComposeMessageForm = (props: InlineComposeMessageFormProps, ref: ForwardedRef<InlineComposeMessageFormRefProps>) => {
    const messageContext = useMessagesContext();
    const userState = useUser();
    const dispatch = useDispatch();
    const [inlineState, setInlineState] = useState<InputState>({
        isEditorOpen: false,
        isRecipientOpen: props.isRecipientOpen,
        isSubjectOpen: props.isSubjectOpen,
    });
    const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const formState = useMessagesComposeForm({
        initialValues: props.initialValues,
    });

    const { setValue } = formState;

    const onSaveDraft = useCallback(async () => {
        const values = formState.getValues();
        // only save if we have a body or we have an existing drafted message
        if (!!values.body || values.attachments.length > 0 || (hasDraftValues(values) && !!values.draftedMessageId)) {
            const draftId = await formState.onDraft(values);
            formState.resetForm({ ...values, draftedMessageId: draftId });
        } else if (values.draftedMessageId && !hasDraftValues(values)) {
            messageContext.onDeleteDraftMessage(values.draftedMessageId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState, messageContext.onDeleteDraftMessage]);

    const checkDigestEnabled = useCallback(async (recipients: string[]) => {
        let isDigestEnabled = false;
        if (recipients.length > 0) {
            const html = await dispatch(taskApi.endpoints.tasksForLoanAndUserAsHtml.initiate({
                loanId: props.initialValues.loanId,
                userId: recipients[0]
            }, {
                forceRefetch: true,
                subscribe: true
            })).unwrap();
            isDigestEnabled = html.length > 0;
        }
        setValue("isDigestEnabled", isDigestEnabled, {
            shouldDirty: true,
            shouldValidate: true
        });
    }, [dispatch, props.initialValues.loanId, setValue])

    useImperativeHandle(ref, () => ({
        setFormValues: (values) => {

            // we need to set the form values
            (Object.keys(values) as Array<keyof ReturnType<InlineComposeMessageFormRefProps['setFormValues']>>)
                .forEach(key => {
                    if (["isRecipientOpen", "isSubjectOpen", "isEditorOpen"].includes(key)) {
                        setInlineState(prevState => ({
                            ...prevState,
                            [key]: values[key]
                        }))
                    } else {
                        if (key === "recipients") {
                            checkDigestEnabled(values[key])
                        }
                        formState.setValue(key, values[key], {
                            shouldDirty: true,
                            shouldValidate: true
                        });
                    }
                })
        },
        getValues: formState.getValues,
        resetForm: formState.resetForm,
        saveDraft: onSaveDraft,
        closeRecipient: () => {
            setInlineState(prevState => ({
                ...prevState,
                isRecipientOpen: false
            }))
        },
    }), [checkDigestEnabled, formState, onSaveDraft])

    const activeSelectedThread = messageContext.threads?.find(thread => thread.id === formState.messageThreadId);
    const { recipientsOptions } = useGetRecipientsUsers({
        loanId: props.initialValues.loanId,
        type: activeSelectedThread?.lendingTeam ? 'LENDERS' : 'ALL'
    });

    useListener("/messages/submitted", () => {
        setInlineState({
            isEditorOpen: false,
            isRecipientOpen: false,
            isSubjectOpen: false,
        })
        formState.reset()
    });

    const onToggleRecipient = () => {
        setInlineState(prevState => {
            // if recipient was previously open
            // we need to clear the recipient field
            if (prevState.isRecipientOpen) {
                formState.setValue("recipients", [], {
                    shouldDirty: true,
                    shouldValidate: true
                })
            }
            if (!isDesktop && !prevState.isRecipientOpen) {
                messageContext.onComposeNewMessageClick()
            }
            return ({
                ...prevState,
                isRecipientOpen: !prevState.isRecipientOpen
            })
        })
    }

    const onSubjectToggle = () => {
        setInlineState(prevState => {
            // if subject was previously open
            // we need to reset the subject field
            // and reset messageThreadId
            if (prevState.isSubjectOpen) {
                formState.setValue("subject", "", {
                    shouldDirty: true,
                    shouldValidate: true
                })
                formState.setValue("messageThreadId", null, {
                    shouldDirty: true,
                    shouldValidate: true
                })
            }
            return ({
                ...prevState,
                isSubjectOpen: !prevState.isSubjectOpen,
                isRecipientOpen: !prevState.isSubjectOpen
            })
        })
    }

    const onEditorToggle = () => {
        setInlineState(prevState => ({
            ...prevState,
            isEditorOpen: !prevState.isEditorOpen
        }))
    }

    const onCancelReplyToMessage = () => {
        messageContext.onReplyToMessage(null);
        formState.bodyProps.ref.current?.focus()
    }

    const onRecipientSelected = (recipients: string[]) => {
        // if logged in user is lender
        // that means user can send digest
        // we need to check if digest is enabled
        // for the first recipient
        // and toggle the digest button accordingly
        if (userState.isLender) {
            checkDigestEnabled(recipients)
        }
        formState.setValue("recipients", recipients, {
            shouldDirty: true,
            shouldValidate: true
        });
    }

    const onAddDigestClick = () => {
        messageContext.onDigestClick(formState.recipients)
    }

    const recipientsList: RecipientSelectProps['options'] = recipientsOptions;
    const defaultRecipients = getDefaultRecipients(recipientsList, formState.recipients);

    const onBodyChange = (args: {
        target: {
            name: string;
            value: string;
        };
    }) => {
        formState.setValue("body", args.target.value, {
            shouldDirty: true,
            shouldValidate: true
        });
        if (props.initialValues?.messageThreadId) {
            // trigger typing only on existing threads
            trigger("/thread/typing/me", { threadId: props.initialValues?.messageThreadId })
        }
    }

    const onPrivateCheckedChange = (checked: boolean) => {
        formState.setValue("locked", checked, {
            shouldDirty: true,
            shouldValidate: true
        });
    }

    useInterval(() => {
        if (formState.isDirty) {
            onSaveDraft()
        }
    }, 5000)



    useEffect(function focusReplyToMessage() {
        if (formState.replyToMessage?.id) {
            formState.bodyProps.ref.current?.focus()
        }
    }, [formState.bodyProps.ref, formState.replyToMessage?.id])


    return {
        ...formState,
        ...inlineState,
        isSubmitting: messageContext.isSubmitting || formState.isSubmitting,
        recipientsList,
        defaultRecipients,
        isRecipientOpen: inlineState.isRecipientOpen,
        isMessageDialogOpen: messageContext.messageWindowStateDialog !== 'CLOSED',
        isEditorOpen: inlineState.isEditorOpen,
        threads: messageContext.threads,
        isLoggedInUserALender: userState.isLender,
        isDigestEnabled: formState.isDigestEnabled,
        loanId: props.initialValues.loanId,
        onEditorReady: messageContext.onEditorReady,
        onEditorToggle,
        onRecipientSelected,
        onPrivateCheckedChange,
        onAddDigestClick,
        onSubjectToggle,
        onBodyChange,
        onCancelReplyToMessage,
        onToggleRecipient,
        onCancel: messageContext.onCancelSendMessage,
        placeholder: getPlaceholder({
            thread: messageContext.activeThread,
            placeholder: props.placeholder,
            loggedInUserId: userState.user.id,
            isReply: !!formState.replyToMessage,
            isSendingInLendingTeam: userState.isLender && formState.recipients.length === 0
        })

    } as const;
}

const getPlaceholder = (args: { thread: MessageThreadDto, placeholder: string, loggedInUserId: string, isReply: boolean, isSendingInLendingTeam: boolean }) => {
    if (args.isReply) {
        return 'Reply message here..'
    } else if (args.thread?.messageThreadCategory === 'DIRECT_NO_SUBJECT' || args.thread?.locked) {
        const otherRecipients = args.thread.users.filter(user => user.user.id !== args.loggedInUserId).map(user => user.user)
        return `Message ${getRecipientsName(otherRecipients)}`
    } else if (args.thread?.id) {
        return `Message ${args.thread.title}`
    } else if (args.isSendingInLendingTeam) {
        return 'Start Message in Lending Team Thread..'
    } else if (args.placeholder) {
        return args.placeholder
    } else {
        return 'Start Message..'
    }
}

const getDefaultRecipients = (options: RecipientSelectProps['options'], selected: string[]) => {
    const recipientUsers = options.map(({ options }) => options).flat();
    const existingUsersRecipients = recipientUsers.filter(({ value }) => selected.includes(value));
    const newRecipients = selected.filter(value => isValidEmail(value)).map(createNewRecipient);
    return [
        ...existingUsersRecipients,
        ...newRecipients,
    ]

}

const getRecipientsName = (recipients: AppUserDTO2[]): string => {
    if (recipients.length === 1) {
        return getUserDisplayName(recipients[0])
    } else if (recipients.length > 1) {
        const recipientGivenNames = recipients.map(user => user.givenName);
        return recipientGivenNames.join(', ')
    } else {
        return ''
    }
}

// create new recipients object from email address
const createNewRecipient = (email: string): RecipientSelectProps['options'][0]['options'][0] => {
    return {
        label: email,
        value: email,
        isMe: false,
        user: {
            givenName: email,
        },
        onLoan: false,
        avatar: <UserAvatar size='xsm'>
            {getInitials(email)}
        </UserAvatar>
    }
}